<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Otps</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Otps</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
<!--            <h4 class="card-title">Otps</h4>-->
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">Otps</h4>
              <download-excel
                class="btn btn-success"
                :data="otps"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="otp.xls">
                Download OTP's
              </download-excel>
            </div>
            <b-table :items="otps" id="table-list" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

              <template v-slot:cell(action)="data">
                <span><button @click="viewPage(data.item.id)" class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button></span>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
//import _ from 'lodash'

import helper from '../../util/helper.js'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      currentPage: 1,
      perPage: 0,
      sortable: true,
      fields: [
        { key: 'otp', sortable: true },
        { key: 'phone', sortable: true},
        { key: 'created_at', sortable: true},
        { key: 'status', sortable: false}
      ],
        items: [

        ],
      json_fields: {
        'Created': 'created_at',
        "OTP": "otp",
        'Phone': 'phone',
        'status': 'status'
      }
    };
  },
  computed: {
    rows() {
      return this.$store.state.admin.otp_meta.total
    },
    otps() {
      return this.$store.state.admin.otps
    }
  },
  watch: {
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    fetchOtps(current_page = 1) {
      const payload = {
        meta: {
          page: current_page,
          perPage: this.paginationPageSize
        },
      }
      this.$store.dispatch("admin/fetchOtps", payload).then(() => {
        this.isBusy = false
      })
    }
  },
  created() {
    this.fetchOtps()
 },
}
</script>
